import getConfig from 'next/config';

import { UserLocale } from './types/translation-types';

const { publicRuntimeConfig } = getConfig();

// Site Constants
export const SiteURL = publicRuntimeConfig.SITE_URL as string;
export const SiteAssetsCDN = publicRuntimeConfig.SITE_ASSETS_CDN as string;
export const MailgunApiKey = process.env.MAILGUN_API_KEY as string;
export const MailgunDomain = process.env.MAILGUN_DOMAIN as string;
export const MailgunEmailTo = process.env.MAILGUN_EMAIL_TO as string;

// Environment Constants
export const isProduction = publicRuntimeConfig.NODE_ENV === 'production';
export const GoogleAnalyticsTrackingId = '';

// User Locales
export const DefaultUserLocale: UserLocale = 'en';
export const AllowedUserLocales: UserLocale[] = ['en', 'es'];
