import classnames from 'classnames';
import React from 'react';

type FooterProps = { className?: string };
const Footer: React.FC<FooterProps> = (props) => {
  return (
    <footer aria-label="Site Footer" className={classnames('text-black', props.className)}>
      <p className="font-serif text-center text-xs mt-4 opacity-60">Copyright @ Bellydanza.com</p>
    </footer>
  );
};
Footer.displayName = 'Footer';
export default Footer;
