import classnames from 'classnames';
import Link from 'next/link.js';
import React from 'react';

import { useTranslations } from '../../../hooks/useTranslations';
import pageRoutes from '../../../router/pageRoutes';
import { UserLocale } from '../../../types/translation-types';
import LanguageSelection from '../../controls/LanguageSelection';
import MenuLinks from './MenuLinks';

type MenuProps = { locale: UserLocale };
const Menu: React.FC<MenuProps> = ({ locale }) => {
  const translations = useTranslations();
  return (
    <div className={classnames('flex justify-between items-center ')}>
      <Link href={pageRoutes.Index}>
        <p className="text-base leading-5 font-semibold">Angelica Sanchez</p>
        <p className="text-xs leading-5"> {translations('menuBiographyLocation')}</p>
      </Link>
      <div>
        <nav aria-label="Site Menu Navigation" className="flex gap-6 text align-middle">
          <MenuLinks locale={locale} />
          <LanguageSelection align="end" />
        </nav>
      </div>
    </div>
  );
};
Menu.displayName = 'Menu';
export default Menu;
