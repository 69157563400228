import classnames from 'classnames';
import { Variants, motion } from 'framer-motion';
import React from 'react';

type DropdownSectionProps = {
  isOpen: boolean;
  children: React.ReactNode;
};
const DropdownSection: React.FC<DropdownSectionProps> = (props) => {
  const { isOpen, children } = props;
  return (
    <motion.div
      className={classnames('absolute left-0 right-0 z-40 h-[100vh] overflow-scroll')}
      initial="hidden"
      animate={isOpen ? 'visible' : 'hidden'}
      variants={{
        hidden: { y: '20px', opacity: 0 },
        visible: { y: 0, opacity: 1, transition: { type: 'spring', bounce: 0.2, duration: 0.3 } },
      }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      {children}
    </motion.div>
  );
};
DropdownSection.displayName = 'DropdownSection';
export default DropdownSection;
