import type { DefaultSeoProps } from 'next-seo';

const defaultSeoConfig: DefaultSeoProps = {
  canonical: 'https://www.bellydanza.com/',
  title: 'Bellydanza',
  themeColor: '#ffffff',
  openGraph: {
    type: 'website',
    locale: 'en_IE',
    url: 'https://www.bellydanza.com/',
    title: 'Bellydanza',
    siteName: 'Bellydanza',
  },
};
export default defaultSeoConfig;
