import classnames from 'classnames';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';

import { DefaultUserLocale } from '../../constants';
import { useClickOutside } from '../../hooks/useClickOutside';
import { UserLocale } from '../../types/translation-types';
import DropdownSection from '../animated/DropdownSection';

type UserLocaleLanguage = { locale: UserLocale; label: string; countryCode: string };

const COUNTRY_CODES: { [key in UserLocale]: string } = {
  en: 'us',
  es: 'es',
};
const COUNTRY_NAMES: { [key in UserLocale]: string } = {
  en: 'English',
  es: 'Español',
};

const LANGUAGES: UserLocaleLanguage[] = Object.keys(COUNTRY_CODES).map((locale) => ({
  locale: locale as UserLocale,
  label: COUNTRY_NAMES[locale as UserLocale],
  countryCode: COUNTRY_CODES[locale as UserLocale],
}));

const DEFAULT_LANGUAGE = LANGUAGES.find(({ locale }) => locale === DefaultUserLocale);

type LanguageSelectionProps = {
  align: 'start' | 'end';
};
const LanguageSelection: React.FC<LanguageSelectionProps> = ({ align }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<UserLocaleLanguage>(DEFAULT_LANGUAGE);

  const clickOutsideRef = useRef(null);
  const router = useRouter();

  useClickOutside({ ref: clickOutsideRef, onClickOutside: () => setIsOpen(false) });

  useEffect(() => {
    setSelectedLanguage(LANGUAGES.find(({ locale }) => locale === (router.locale || DefaultUserLocale)));
  }, [router.locale]);

  const handleLanguageChange = useCallback(
    (language: UserLocaleLanguage) => {
      const { pathname, asPath, query } = router;
      router.push({ pathname, query }, asPath, { locale: language.locale });
      setIsOpen(false);
    },
    [router],
  );
  return (
    <div className={classnames('relative inline-block z-10 w-36')} ref={clickOutsideRef}>
      <LanguageSelectionTrigger selectedLanguage={selectedLanguage} isExpanded={isOpen} align={align} onClick={() => setIsOpen(!isOpen)} />
      {isOpen && (
        <DropdownSection isOpen={true}>
          <LanguageSelectionPanel
            selectedLanguage={selectedLanguage}
            onSelectedLanguageChange={handleLanguageChange}
            languages={LANGUAGES}
          />
        </DropdownSection>
      )}
    </div>
  );
};
LanguageSelection.displayName = 'LanguageSelection';
export default LanguageSelection;

/************************************************************************************************
 * Helpers
 ************************************************************************************************/

type LanguageSelectionPanelProps = {
  languages: UserLocaleLanguage[];
  selectedLanguage: UserLocaleLanguage;
  onSelectedLanguageChange: (language: UserLocaleLanguage) => void;
};
const LanguageSelectionPanel: React.FC<LanguageSelectionPanelProps> = React.memo((props) => {
  const { languages, selectedLanguage, onSelectedLanguageChange } = props;
  return (
    <div
      className="z-50 origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="language-selector"
    >
      <div className="py-1 grid grid-cols-1 gap-0" role="none">
        {languages.map((language) => (
          <button
            key={language.locale}
            onClick={() => onSelectedLanguageChange(language)}
            className={`gap-1 px-3 py-1 text-md md:text-sm text-left items-center inline-flex hover:bg-gray-200 ${
              selectedLanguage.locale === language.locale ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
            }`}
            role="menuitem"
          >
            <span className="truncate">{language.label}</span>
          </button>
        ))}
      </div>
    </div>
  );
});
LanguageSelectionPanel.displayName = 'LanguageSelectionPanel';

type LanguageSelectionTriggerProps = {
  selectedLanguage: UserLocaleLanguage;
  isExpanded: boolean;
  align: 'start' | 'end';
  onClick: () => void;
};
const LanguageSelectionTrigger: React.FC<LanguageSelectionTriggerProps> = ({ selectedLanguage, align, isExpanded, onClick }) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={classnames(
        'inline-flex items-center gap-1 w-full rounded-md text-md md:text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none',
        {
          'justify-end': align === 'end',
          'justify-start': align === 'start',
        },
      )}
      aria-haspopup="true"
      aria-expanded={isExpanded}
    >
      <span className="inline-block">{selectedLanguage.label}</span>
      {isExpanded && <AiFillCaretUp />}
      {!isExpanded && <AiFillCaretDown />}
    </button>
  );
};
LanguageSelectionTrigger.displayName = 'LanguageSelectionTrigger';
