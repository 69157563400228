import classnames from 'classnames';
import Link, { LinkProps } from 'next/link.js';
import React from 'react';

import { DefaultUserLocale } from '../../../constants';
import { useTranslations } from '../../../hooks/useTranslations';
import pageRoutes from '../../../router/pageRoutes';
import { DictionaryDefinition, UserLocale } from '../../../types/translation-types';

const menuLinksItems: Array<{ href: string; label: keyof DictionaryDefinition }> = [
  { href: pageRoutes.Index, label: 'menuLinkHome' },
  { href: pageRoutes.Gallery, label: 'menuLinkGallery' },
  { href: pageRoutes.About, label: 'menuLinkAbout' },
  { href: pageRoutes.Contact, label: 'menuLinkContact' },
];

type MenuLinksProps = {
  locale: UserLocale;
  className?: string;
  onClick?: LinkProps['onClick'];
};
const MenuLinks: React.FC<MenuLinksProps> = ({ locale, className, onClick }) => {
  const translations = useTranslations();
  return (
    <>
      {menuLinksItems.map((item, index) => (
        <Link
          onClick={onClick}
          key={`menu-link-${index}`}
          href={item.href}
          locale={locale}
          className={classnames('group transition duration-300', className)}
        >
          {translations(item.label)}
          <span className="block max-w-0 group-hover:max-w-full transition-all duration-200 h-0.5 bg-yellow-300"></span>
        </Link>
      ))}
    </>
  );
};
MenuLinks.displayName = 'MenuLinks';
export default MenuLinks;
