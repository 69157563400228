import { SpeedInsights } from '@vercel/speed-insights/next';
import classnames from 'classnames';
import Head from 'next/head';
import Script from 'next/script';
import React from 'react';

import { GoogleAnalyticsTrackingId, isProduction } from '../../constants';
import { UserLocale } from '../../types/translation-types';
import Footer from './Footer';
import Header from './Header';
import { HEADER_HEIGHT } from './constants';

type AppContainerProps = {
  className?: string;
  children?: React.ReactNode;
  locale: UserLocale;
  excludeContainer?: boolean;
};
const AppContainer: React.FC<AppContainerProps> = (props) => {
  const { className, locale, excludeContainer, children } = props;
  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://web.com" />
      </Head>
      <div className={classnames('flex flex-col h-screen justify-between relative', className)}>
        <div style={{ height: HEADER_HEIGHT }} className={`bg-pureWhite text-black fixed w-full flex items-center z-20 `}>
          <Header className="w-full max-w-[1600px] px-12 mx-auto" locale={locale} />
        </div>
        <main
          style={{ marginTop: HEADER_HEIGHT }}
          className={classnames('w-full', { 'max-w-[1600px] px-4 md:px-8 mx-auto': !excludeContainer })}
        >
          {children}
        </main>
        <Footer className="w-full py-8 px-8 mx-auto" />
        {isProduction && GoogleAnalyticsTrackingId && (
          <>
            <Script
              async
              id="google-analytics-tag"
              src={`https://www.googletagmanager.com/gtag/js?id=${GoogleAnalyticsTrackingId}`}
              strategy="afterInteractive"
            />
            <Script id="google-analytics-init" async strategy="afterInteractive">
              {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${GoogleAnalyticsTrackingId}');`}
            </Script>
          </>
        )}
        <SpeedInsights />
      </div>
    </>
  );
};
AppContainer.displayName = 'AppContainer';
export default AppContainer;
