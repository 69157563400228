
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { DefaultSeo } from 'next-seo';
import { Merriweather, Raleway } from 'next/font/google';
import { Router } from 'next/router';
import { useReportWebVitals } from 'next/web-vitals';
import React, { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { SWRConfig } from 'swr';
import AppContainer from '../components/layout/AppContainer';
import { GoogleAnalytics } from '../lib/analytics/gtag';
import defaultSeoConfig from '../lib/seo/defaultSeoConfig';
import ErrorBoundaryRenderer from '../renderers/ErrorBoundaryRenderer';
import '../styles/tailwind.css';
import { DefaultPageStaticProps, NextPageWithLayout, NextPageWithLayoutDefaultPageProps } from '../types/global-types';
const reportableMetrics = ['Next.js-hydration', 'Next.js-route-change-to-render', 'Next.js-render'];
const ralewayFont = Raleway({
    subsets: ['latin'],
    variable: '--font-raleway',
    style: ['normal', 'italic'],
    weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
});
const merriweatherFont = Merriweather({
    subsets: ['latin'],
    variable: '--font-merriweather',
    style: ['normal', 'italic'],
    weight: ['300', '400', '700', '900'],
});
export const apiFetcher = (resource: string, init?: RequestInit) => {
    return fetch(resource, init).then((res) => res.json());
};
type ApplicationRootProps = {
    Component: NextPageWithLayout<NextPageWithLayoutDefaultPageProps>;
    router: Router;
    pageProps: DefaultPageStaticProps;
};
const ApplicationRoot: React.FC<ApplicationRootProps & any> = ({ Component, pageProps, router }) => {
    const CastedComponent = Component as any;
    useReportWebVitals((metric) => {
        if (!reportableMetrics.includes(metric)) {
            return;
        }
        const logEndpoint = '/api/log-info';
        const body = `NextJS WebVitals: ${JSON.stringify(metric)}`;
        const options = { method: 'POST', headers: { 'Content-Type': 'application/json' }, body, keepalive: true };
        return !!navigator.sendBeacon ? navigator.sendBeacon(logEndpoint, body) : fetch(logEndpoint, options);
    });
    useEffect(() => {
        const handleRouteChange = (url: string) => GoogleAnalytics.trackPageView(url);
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);
    return (<CookiesProvider cookies={Component.universalCookies}>
      <ErrorBoundaryRenderer>
        <DefaultSeo {...defaultSeoConfig}/>
        <SWRConfig value={{ fetcher: apiFetcher }}>
          <AppContainer excludeContainer={pageProps.excludeContainer} locale={pageProps.locale} className={`${ralewayFont.variable} ${merriweatherFont.variable} font-sans`}>
            <CastedComponent {...pageProps}/>
          </AppContainer>
        </SWRConfig>
      </ErrorBoundaryRenderer>
    </CookiesProvider>);
};
const __Next_Translate__Page__1923ae1b8d7__ = ApplicationRoot;

    export default __appWithI18n(__Next_Translate__Page__1923ae1b8d7__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  