import { GoogleAnalyticsTrackingId, isProduction } from '../../constants';

type WindowWithGtag = Window & { gtag: (property: string, value: string, options: Record<string, unknown>) => void };

const trackPageView = (urlPath: string) => {
  const transformUrlPathToTitle = (urlPath: string) => {
    const path = urlPath.replaceAll('-', ' ').replaceAll('/', ' ');
    return path
      .split(' ')
      .map((term) => term.charAt(0).toUpperCase() + term.slice(1))
      .join(' ');
  };
  if (!GoogleAnalyticsTrackingId) {
    return;
  }
  (window as unknown as WindowWithGtag).gtag('config', GoogleAnalyticsTrackingId, {
    page_location: urlPath,
    page_title: transformUrlPathToTitle(urlPath),
  });
};

type TrackEventParams = {
  action: string;
  category: string;
  label: string;
  value: string | number | boolean;
};
const trackEvent = ({ action, category, label, value }: TrackEventParams) => {
  (window as unknown as WindowWithGtag).gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

export const GoogleAnalytics = isProduction ? { trackEvent, trackPageView } : { trackEvent: () => {}, trackPageView: () => {} };
