import useTranslation from 'next-translate/useTranslation';

import type { DictionaryDefinition, DictionaryNamespace } from '../types/translation-types';

type AllowedKey = keyof DictionaryDefinition;

export const useTranslations = (namespace: DictionaryNamespace = 'default') => {
  const { t } = useTranslation(namespace);
  return (key: AllowedKey, query?: { [name: string]: unknown }) => t(key, query);
};
