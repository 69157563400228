const pageRoutes = {
  Index: '/',
  About: '/about',
  Contact: '/contact',
  Gallery: '/gallery',
} as const;

export type PageRoute = (typeof pageRoutes)[keyof typeof pageRoutes];

export default pageRoutes;
