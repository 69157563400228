import classnames from 'classnames';
import { motion } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import { AiOutlineCloseCircle, AiOutlineMenu } from 'react-icons/ai';

import { useClickOutside } from '../../../hooks/useClickOutside';
import { UserLocale } from '../../../types/translation-types';
import LanguageSelection from '../../controls/LanguageSelection';
import { HEADER_HEIGHT } from '../constants';
import MenuLinks from './MenuLinks';

type MobileMenuProps = { locale: UserLocale };

const MobileMenu: React.FC<MobileMenuProps> = ({ locale }) => {
  const clickOutsideRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  useClickOutside({
    ref: clickOutsideRef,
    onClickOutside: () => setIsExpanded(false),
  });

  // Close the menu when the ESCAPE key is pressed
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') setIsExpanded(false);
    };
    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, []);

  return (
    <div className={classnames('flex justify-between items-center')}>
      <div className="flex flex-row justify-between w-full">
        <LanguageSelection align="start" />
        <button className="cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <AiOutlineCloseCircle size={28} /> : <AiOutlineMenu size={28} />}
        </button>
      </div>
      <motion.div
        ref={clickOutsideRef}
        aria-label="SubMenu Navigation"
        style={{ top: HEADER_HEIGHT }}
        className={classnames('absolute left-0 right-0 z-40 top-200 py-4 overflow-scroll bg-white', {
          'h-0': !isExpanded,
          'h-[100vh]': isExpanded,
        })}
        initial="hidden"
        animate={isExpanded ? 'visible' : 'hidden'}
        variants={{ hidden: { x: '20%', opacity: 0 }, visible: { x: 0, opacity: 1 } }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        <MenuLinks
          onClick={() => setIsExpanded(false)}
          className="flex px-5 mb-5 flex-col gap-2 text-2xl font-semibold text-black"
          locale={locale}
        />
      </motion.div>
    </div>
  );
};
MobileMenu.displayName = 'MobileMenu';
export default MobileMenu;
